import Container from "./Container.jsx";

export default function HeroSection({ children }) {
  return (
    <div className="relative" id="home">
      <div aria-hidden="true" className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
      </div>
      <video id="bg-video" className="absolute top-[-20vh] left-0 w-screen h-[140vh] object-cover z-0" autoPlay muted loop playsInline>
        <source src="/universe.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Container className="z-20">
        <div className="relative pt-36 ml-auto">
          <div className="lg:w-2/3 text-center mx-auto">
            <div className="mt-[20vh] text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl">
              <span className="text-white dark:text-white">Launching a journey to space</span>
            </div>
            <div className="mt-24 flex flex-wrap justify-center gap-y-4 gap-x-6">
              <a
                href="/quest"
                className="btn h-11 w-[200px]"
              >
               Quest
              </a>
              <a
                href="/mining"
                className="btn h-11 w-[200px]"
              >
                Mining
              </a>
              <a
                href="/claim"
                className="btn h-11 w-[200px]"
              >
                Claim Fees
              </a>
            </div>
            <div className="hidden py-8 mt-16 border-y text-white border-gray-100 dark:border-gray-800 sm:flex justify-between">
              <div className="text-left">
                <h6 className="text-lg font-semibold text-white dark:text-white">Planet of the apes</h6>
                <p className="mt-2 text-white">1,816 unique NFTs</p>
              </div>
              <div className="text-left">
                <h6 className="text-lg font-semibold text-white dark:text-white">APIUM is live</h6>
                <p className="mt-2 ttext-white"><a href="https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/token/0x970f2e60b1d66381f5f9d279d31f0d5f09ceebe6" className="text-blue-500 hover:underline">Contract</a></p>
            </div>
              <div className="text-left">
                <h6 className="text-lg font-semibold text-white dark:text-white">Community driven</h6>
                <p className="mt-2 text-white">Join the quest</p>
              </div>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/microsoft.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/airbnb.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 flex grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/google.svg" className="h-9 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/ge.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 flex grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/netflix.svg" className="h-8 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/google-cloud.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}