import Container from "./Container.jsx";

export default function About({ children }) {
  return (
    <div id="about">
      <Container>
        <div className="mt -12 mb-24 space-y-2 text-center">
          <h2 className="mb-6 text-center text-4xl font-bold text-white dark:text-white md:text-5xl">Pulse Space Apes</h2>
          <p className="lg:mx-auto text-xl lg:w-6/12 text-white dark:text-gray-300">
            1,816 unique NFTs where royalties are paid out to the holders with the possibility to stake your NFT for APIUM.
          </p>
        </div>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="group p-6 sm:p-8 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
              <img src="./images/psa1.png"
                alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" />
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white">
                About the Space Apes
              </h3>
              <a className="inline-block" href="/">
                <span className="text-white dark:text-blue-300">
                  <div className="mt-6 mb-8 text-white dark:text-gray-300">
                    <ul>
                      {/* <li>Background: Morning_Magic</li>
                      <li>Body: Earthen</li>
                      <li>Suit: Doom_Vest</li>
                      <li>Face: Diamond_Fangz</li>
                      <li>Head: Devil</li> */}
                      <li>The first Pulse Space Apes contract consists of 3,000 unique NFTs and was the second project to be fully minted on PulseChain. With the departure of the original founder, this project has become entirely community-driven. To proceed with an enhanced roadmap, we have swapped 1,816 NFTs for a new version, laying the foundation for our community's future work.</li>
                    </ul>
                  </div>
                </span>
              </a>
            </div>

          </div>
          <div className="group p-6 sm:p-8 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
              <img src="./images/psa76.png"
                alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" />
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white">
                {/* The first Apes to arrive on PulseChain. Join us in our battle. */}
                Roadmap
              </h3>
              <a className="inline-block" href="/">
                <span className="text-white dark:text-blue-300">
                  <div className="mt-6 mb-8 text-white dark:text-gray-300">
                    <ul>
                      {/* <li>Background: Kwai_Bridge</li>
                      <li>Body: Diamond</li>
                      <li>Suit: Bando_Straps</li>
                      <li>Face: Red_Laser</li>
                      <li>Head: Crown</li> */}
                      <li>Launch Swap and Royalty contract (done)</li>
                      <li>V1 Dapp for Swap functionality (done)</li>
                      <li>Start swap for 3 months (done)</li>
                      <li>End of swap period (done)</li>
                      <li>Start of claiming Royalties (done)</li>
                      <li>Beginning of Quest (ongoing)</li>
                      <li>V2 Dapp (done)</li>
                      <li>Launch APIUM token (done)</li>
                      <li>Launch staking (done)</li>
                      <li>V3 Dapp (done)</li>
                      <li>Start Farming</li>
                    </ul>
                  </div>
                </span>
              </a>
            </div>

          </div>
          <div className="group p-6 sm:p-8 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
              <img src="./images/psa116.png"
                alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" />
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white">
                Swap Contract
              </h3>
              <a className="inline-block" href="/">
                <span className="text-white dark:text-blue-300">
                  <div className="mt-6 mb-8 text-white dark:text-gray-300">
                    <ul>
                      {/* <li>Background: VibZik_88</li>
                      <li>Body: Diamond</li>
                      <li>Suit: Golden_Scales</li>
                      <li>Face: Cyborg</li>
                      <li>Head: Octo_Slime</li> */}
                      <li>Why a new contract? In the old contract, there are no royalties; these are withheld via the marketplace of the previous developer, which has been offline for some time. Additionally, the claim contract has not been verified. The new contract will have a fee, and a claim contract that will be automatically activated after three months of the swap period. Multiple products will be built on the new contract, where ranking will play a role.</li>
                    </ul>
                  </div>
                </span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}