import { Web3Button } from "./Web3Button";
import Container from "./Container.jsx";
import { useAccount, useContractRead, useNetwork } from "wagmi";

const splitterContractAbi = [
  {
    "inputs": [],
    "name": "claimForHolders",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amountClaimable",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claimForTreasury",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claimStartTimeStamp",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
];

export default function Claim({ children }) {
  const { address: userAddress } = useAccount();
  const { chain } = useNetwork();

  const splitterContractAddress = chain?.id === 369 ? "0x03d1Ba8f4410A53E93410c6C290BBF0337dF44CA" : "0x7cd2B3cD10691D89e3Af101edbb94fC1A45E1566";

  const { data, isLoading } = useContractRead({
    address: splitterContractAddress,
    abi: splitterContractAbi,
    functionName: "claimForHolders",
    account: userAddress,
    enabled: !!userAddress,
  });

  return (
    <div id="claim">
      <Container>
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-sky-500">
          <path fillRule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z" clipRule="evenodd" />
          <path fillRule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z" clipRule="evenodd" />
        </svg> */}

        <div className="space-y-6 justify-between text-gray-600 md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center">
          {/* <div className="md:5/12 lg:w-1/2">
            <img
              src="./images/pie.svg"
              alt="claim"
              loading="lazy"
              width=""
              height=""
              className="w-full"
            />
          </div> */}
          {/* <div className="md:7/12 lg:w-1/2"> */}
          <div className="w-full mt-8">
            <h2 className="text-2xl font-bold text-white text-center">
              Claim the collected royalties here
            </h2>
            <div className="max-w-prose mx-auto text-center">
              <div className="my-8 text-white flex flex-col items-center">
                {userAddress ?
                  (isLoading ?
                    <span>Loading fees...</span>
                    :
                    <span>You are eligible to {Math.round(Number(data) / 1e18 * 100) / 100} PLS in fees.</span>
                  )
                  :
                  <span>Connect you wallet to check elligibility/.</span>
                }
                <Web3Button
                  disabled={!userAddress}
                  className="my-8 h-11 text-xl w-[200px] sm:w-52 items-center justify-center px-6 border border-primary bg-transparent hover:bg-primary hover:text-white transition-colors rounded-full active:duration-75 active:before:scale-95"
                  contractAddress={splitterContractAddress}
                  contractAbi={splitterContractAbi}
                  action={async (ctx) => {
                    return ctx.estimateAndSend("claimForHolders", []);
                  }}
                  onError={(error) => { alert(error); }}
                  onSuccess={() => { }}
                >
                  Claim Fees
                </Web3Button>
                From each trade, a 6.55% royalty is deducted to the splitter contract, with 5.55% evenly distributed among all holders.
                Additionally, 1% is allocated to the treasury of Hocus Pocus and PulseMarket. <br /> <br />
              </div>
              {/* <div className="divide-y space-y-4 divide-gray-100 dark:divide-gray-800">
                <div className="mt-8 flex gap-4 md:items-center">
                  <div className="w-12 h-12 flex gap-4 rounded-full bg-indigo-100 dark:bg-indigo-900/20">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 m-auto text-indigo-500 dark:text-indigo-400">
                      <path fillRule="evenodd" d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="w-5/6">

                    <h3>
                      <a href="https://t.me/pulsespaceapesNFT/" className="font-semibold text-lg text-gray-700 dark:text-indigo-300">Talk with our community</a>  </h3>
                    <a href="https://t.me/pulsespaceapesNFT/" className="font-semibold text-lg text-gray-700 dark:text-indigo-300">Join our Telegram</a>
                  </div>
                </div>
                <div className="pt-4 flex gap-4 md:items-center">
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Container >
    </div >
  );
}