import Container from "./Container.jsx";

export default function CallToAction({ children }) {
  return (
    <div className="relative">
      <div aria-hidden="true" className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>
      <Container>
        <div className="relative">
          <div className="mt-24 mb-24 flex items-center justify-center -space-x-2">
            <img
              loading="lazy"
              width="400"
              height="400"
              src="./images/avatars/psa220.png"
              alt="space ape"
              className="h-8 w-8 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="./images/avatars/psa226.png"
              alt="space ape"
              className="h-12 w-12 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="./images/avatars/psa314.png"
              alt="space ape"
              className="z-10 h-16 w-16 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="./images/avatars/psa242.png"
              alt="space ape"
              className="relative h-12 w-12 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="./images/avatars/psa284.png"
              alt="space ape"
              className="h-8 w-8 rounded-full object-cover"
            />
          </div>
          <div className="mt-6 m-auto space-y-6 md:w-8/12 lg:w-7/12">
            <h1 className="text-center text-4xl font-bold text-white dark:text-white md:text-5xl">Space Apes Journey</h1>
            <p className="text-center text-xl text-white dark:text-gray-300">
              In a groundbreaking experiment on the PulseChain network, a human scientist created something amazing. Through a series of genetic modifications and intensive training programs, he successfully created 3,000 uniquely intelligent apes, each embedded with a distinct NFT, making them one of the first fully minted NFTs of their kind.</p>
            <p className="text-center text-xl text-white dark:text-gray-300">
              However, as these apes grew smarter and more aware, they recognized a sudden absence - their creator, the scientist, had vanished without a trace. Rumors among the apes suggested he had ventured into the vastness of space, seeking answers to questions even beyond their enhanced comprehension. Feeling abandoned and yearning for their creator, the apes decided to embark on an interstellar journey to find him.</p>
            <div>
              <div className="relative">
                <div className="mt-24 mb-20 flex items-center justify-center -space-x-2">
                  <img
                    loading="lazy"
                    width="400"
                    height="400"
                    src="./images/avatars/psa220.png"
                    alt="space ape"
                    className="h-32 w-32 rounded-full object-cover" />
                </div>
              </div>
              <div className="mt-6 m-auto space-y-6">
                <p className="text-center text-xl text-white dark:text-gray-300">
                But their journey was not without challenges. The sudden loss of their community leader forced the apes to adapt, innovate, and instigate significant changes. They drafted a new contract and continued with 1,816 apes, ensuring that the fruits of their labor, in the form of royalties, would be shared and paid out with every trade among them. Their resilience was evident, and their motivation unwavering. Recognizing the strengths within their community, developers took charge where the previous one had faltered. Marketing experts among them charted out strategies to ensure their quest was known across galaxies.</p>
                <p className="text-center text-xl text-white dark:text-gray-300">
                  Now, as the next chapter of their quest is about to begin, they find themselves in the dense jungles and they will travel through the vast expanse of space, bringing them closer with every step to finding their planet they can call home.</p>
                <div className="mt-6 flex flex-wrap justify-center gap-6">
                  <a
                    href="/"
                    className="mt-24 relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max"
                  >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
