import { useState } from "react";
import { useNetwork, usePublicClient, useWalletClient } from "wagmi";
import { Spinner } from "./Spinner";

export async function estimateAndSend(
  publicClient, walletClient,
  address, abi,
  functionName,
  args,
  overrides
) {
  let params = {
    account: walletClient.account.address,
    address,
    abi,
    functionName,
    args,
  };
  if (overrides)
    params = Object.assign(params, overrides);
  params.gas = await publicClient.estimateContractGas(params);
  return await walletClient.writeContract(params);
}

export function Web3Button({ contractAddress, contractAbi, action, onError, onSuccess, children, className, disabled }) {
  const [isActing, setIsActing] = useState(false);
  const publicClient = usePublicClient();
  const { /*isSuccess,*/ isLoading: signerIsLoading, data: walletClient } = useWalletClient();
  // const { openChainModal } = useChainModal();
  const { chain } = useNetwork();
  const [isConfirming, setIsConfirming] = useState(false);

  const goodChain = !!chain;

  async function onClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    // if (!isSuccess) {
    //   initConnection();
    //   return;
    // }
    // if (!goodChain) {
    //   openChainModal();
    //   return;
    // }
    setIsActing(true);
    try {
      const ctx = {
        address: contractAddress,
        abi: contractAbi,
        publicClient,
        walletClient,
        readContract: async function (functionName, args) {
          return publicClient.readContract({
            address: contractAddress,
            abi: contractAbi,
            functionName, args
          });
        },
        estimateAndSend: async function (functionName, args, overrides = undefined) {
          return estimateAndSend(
            publicClient, walletClient,
            contractAddress, contractAbi,
            functionName, args, overrides
          );
        },
      };
      const txHash = await action(ctx);
      setIsActing(false);
      if (txHash) {
        setIsConfirming(true);
        const txResult = await publicClient.waitForTransactionReceipt({ hash: txHash });
        if (onSuccess)
          await onSuccess(txResult);
        setIsConfirming(false);
      }
      setIsActing(false);
    }
    catch (err) {
      setIsActing(false);
      if (onError)
        await onError(err);
      setIsConfirming(false);
    }
  }

  return <button
    className={`${className ? className + " " : ""}flex flex-row btn btn-primary ${isActing || isConfirming ? "disabled:btn-warning" : ""} ${goodChain ? "" : "btn-error"}`}
    onClick={onClick}
    disabled={isActing || isConfirming || disabled}
  >
    <Spinner when={signerIsLoading || isActing} />
    <Spinner when={isConfirming} className="text-warning" />
    {children}
  </button>;
}