import { useEffect, useMemo, useState } from "react";
import { NumericFormat } from 'react-number-format';
import { useAccount, useBalance, useNetwork, usePublicClient } from "wagmi";
import PulseSpaceApesAbi from '../abis/PulseSpaceApes.json';
import PulseSpaceApesTokenAbi from '../abis/PulseSpaceApesToken.json';
import { Spinner } from "./Spinner";
import { Web3Button } from "./Web3Button";
import { formatEther, parseEther, parseUnits } from "viem";

function formatAmount(amount) {
	if (amount === undefined)
		return undefined;
	return Number(formatEther(amount)).toLocaleString();
}

function NFTDisplay({ tokenId, minerInfo, refresh, tokenContractAddress, modalMinerShow }) {
	const miningActive = minerInfo.minedAmount > 0n;
	const principalHasPenalty = minerInfo.minedAmount !== minerInfo.returnedAmountWithPenalty;
	const yieldHasPenalty = minerInfo.yield !== minerInfo.yieldWithPenalty;
	const isEarly = minerInfo.servedDays < minerInfo.durationDays;

	return <div className="flex flex-row gap-4 border rounded-xl p-4 border-[#a061ae] w-full">
		<div className="flex flex-col items-center w-28 shrink-0">
			<div className="text-sm text-center"><span className="hidden xs:inline">Space Ape</span> #{`${tokenId}`}</div>
			<img
				alt={`Ape ${tokenId}`}
				src={`https://ipfs.pulsemarket.app/ipfs/QmdheM2UEhjs9mStQzGuRfq5SnBaXW6CB5ntvtqFg57wYe/${tokenId}.png`}
				className="shrink-0 w-16 xs:w-24"
			/>
			<div className="text-sm">Rank #{minerInfo.rank}</div>
		</div>
		<div className="flex flex-col justify-between gap-2">
			<div className="flex flex-col">
				<div>
					APR: {miningActive
						? `${minerInfo.apr / 100}%`
						: `${minerInfo.apr7 / 100} - ${minerInfo.apr365 / 100}%`
					}
				</div>
				{miningActive ?
					<>
						<div>
							Principal: {formatAmount(minerInfo.minedAmount)} APIUM
							{principalHasPenalty && <span className="text-[#f00]"> -25%</span>}
						</div>
						<div>
							Yield: {formatAmount(minerInfo.yield)} APIUM
							{yieldHasPenalty && <span className="text-[#f00]"> -100%</span>}
						</div>
						<div>
							Day: {minerInfo.servedDays} of {minerInfo.durationDays}
						</div>
					</>
					:
					<div>Mining not active</div>
				}
			</div>
			<div className="flex flex-col xs:flex-row xs:flex-wrap gap-2">
				<button
					className="btn w-32 h-11 items-center justify-center border border-primary bg-transparent hover:bg-primary hover:text-white transition-colors rounded-full active:duration-75 active:before:scale-95"
					onClick={ev => modalMinerShow(tokenId)}
				>
					Mining
				</button>
				{miningActive && <Web3Button
					disabled={false}
					className={`h-11 w-32 items-center justify-center border border-primary bg-transparent ${isEarly ? " !bg-[#f00]" : ""} hover:bg-primary hover:text-white transition-colors rounded-full active:duration-75 active:before:scale-95`}
					contractAddress={tokenContractAddress}
					contractAbi={PulseSpaceApesTokenAbi.abi}
					action={async (ctx) => {
						return ctx.estimateAndSend("stopMining", [tokenId]);
					}}
					onError={(error) => { alert(error); }}
					onSuccess={() => { refresh(); }}
				>
					Finish
				</Web3Button>}
			</div>
		</div>
	</div >;
}

export function Mining({ children }) {
	const { address } = useAccount();
	const { chain } = useNetwork();
	const publicClient = usePublicClient();

	const [isLoading, setIsLoading] = useState(true);
	const [isFetching, setFetching] = useState(false);
	const [tokenIds, setTokenIds] = useState([]);
	const [minerInfos, setMinerInfos] = useState([]);
	const [revision, setRevision] = useState(0);

	const nftContractAddress = chain?.id === 369 ? "0xB5B915c965b2EAAc3154A8F42E260C514a01D380" : "0x763C156e1a0e12844cd4E5a9C38cED49F022e38B";
	const tokenContractAddress = chain?.id === 369 ? "0x970F2E60B1d66381f5f9D279D31f0D5F09ceEBe6" : "0xaB4644bb1a1f4A67f897F2645fd19632a347ba7c";

	useEffect(() => {
		async function doFetch(address) {
			try {
				const contracts = [];
				const tokenCount = await publicClient.readContract({
					address: nftContractAddress,
					abi: PulseSpaceApesAbi.abi,
					functionName: "balanceOf",
					args: [address],
				})

				contracts.length = 0;
				for (let nftIndex = 0n; nftIndex < tokenCount; ++nftIndex) {
					contracts.push({
						address: nftContractAddress,
						abi: PulseSpaceApesAbi.abi,
						functionName: "tokenOfOwnerByIndex",
						args: [address, nftIndex],
					});
				}
				let tokenIds = await publicClient.multicall({ contracts });
				tokenIds = tokenIds.filter(_ => _.status === "success").map(_ => Number(_.result))
				setTokenIds(tokenIds);

				contracts.length = 0;
				for (let nftIndex = 0n; nftIndex < tokenCount; ++nftIndex) {
					contracts.push({
						address: tokenContractAddress,
						abi: PulseSpaceApesTokenAbi.abi,
						functionName: "calculateAprForToken",
						args: [tokenIds[nftIndex], 7],
					});
					contracts.push({
						address: tokenContractAddress,
						abi: PulseSpaceApesTokenAbi.abi,
						functionName: "calculateAprForToken",
						args: [tokenIds[nftIndex], 365],
					});
					contracts.push({
						address: tokenContractAddress,
						abi: PulseSpaceApesTokenAbi.abi,
						functionName: "tokenRank",
						args: [tokenIds[nftIndex]],
					});
					contracts.push({
						address: tokenContractAddress,
						abi: PulseSpaceApesTokenAbi.abi,
						functionName: "calculateProgress",
						args: [tokenIds[nftIndex], false],
					});
					contracts.push({
						address: tokenContractAddress,
						abi: PulseSpaceApesTokenAbi.abi,
						functionName: "calculateProgress",
						args: [tokenIds[nftIndex], true],
					});
				}
				const result = await publicClient.multicall({ contracts });
				const minerInfos = [];
				for (let i = 0; i < result.length; i += 5) {
					const apr7 = result[i];
					const apr365 = result[i + 1];
					const rank = result[i + 2];
					const progress = result[i + 3];
					const progressWithPenalty = result[i + 4];
					const obj = {
						apr7: apr7?.status === "success" ? Number(apr7.result) : null,
						apr365: apr365?.status === "success" ? Number(apr365.result) : null,
						rank: rank?.status === "success" ? Number(rank.result) : null,
						durationDays: progress?.status === "success" ? Number(progress.result[0]) : null,
						servedDays: progress?.status === "success" ? Number(progress.result[1]) : null,
						apr: progress?.status === "success" ? Number(progress.result[2]) : null,
						minedAmount: progress?.status === "success" ? progress.result[3] : null,
						returnedAmount: progress?.status === "success" ? progress.result[4] : null,
						returnedAmountWithPenalty: progressWithPenalty?.status === "success" ? progressWithPenalty.result[4] : null,
						yield: progress?.status === "success" ? progress.result[5] : null,
						yieldWithPenalty: progressWithPenalty?.status === "success" ? progressWithPenalty.result[5] : null,
					};
					minerInfos.push(obj);
				}
				setMinerInfos(minerInfos);
			}
			catch (e) {
				console.log(e);
			}
			setIsLoading(false);
			// setFetching(false);
		}

		if (publicClient && address && !isFetching) {
			setFetching(true);
			doFetch(address);
		}
	}, [publicClient, address, isFetching, nftContractAddress, tokenContractAddress, revision]);

	function refresh() {
		setFetching(false);
		setIsLoading(true);
		setTokenIds([]);
		setRevision(x => x + 1);
	}

	const { data: userBalance } = useBalance({
		address,
		token: tokenContractAddress,
		watch: true,
	});

	const [modalMinerTokenId, setModalMinerTokenId] = useState(0);

	function modalMinerShow(tokenId) {
		console.log("modalMinerShow", tokenId);
		setModalMinerTokenId(tokenId);
		document.getElementById('minerModal').showModal();
		document.getElementById('minerBalance').focus();
	}

	function modalMinerClose() {
		setModalMinerTokenId(0);
		document.getElementById('minerModal').close();
	}

	function modalCalculatorShow() {
		document.getElementById('calculatorModal').showModal();
		document.getElementById('calculatorBalance').focus();
	}

	function modalCalculatorClose() {
		document.getElementById('calculatorModal').close();
	}

	return <div className="mb-40 text-white ml-2 mr-2 lg:ml-20 lg:mr-20">
		{address ?
			<>
				<div className="mt-8 flex sm:flex-row flex-col justify-center gap-6 items-center">

					<button
						className="btn h-11 text-xl sm:w-48 w-full px-6"
						onClick={refresh}
					>
						Refresh
					</button>
					<button
						className="btn h-11 text-xl sm:w-48 w-full px-6"
						onClick={modalCalculatorShow}
					>
						Calculator
					</button>
				</div>

				{/* <div className="text-2xl mt-8 text-center">
					Space Apes NFTs
					{tokenIds?.length > 0 && <span>{' '}({tokenIds.length})</span>}
				</div> */}
				<div className="mt-8">
					{isLoading ?
						<div className="flex flex-col items-center"><Spinner when={true} /></div>
						:
						(tokenIds?.length > 0 ?
							<div className="grid xs:grid-cols-fluid-lg gap-4">
								{tokenIds.map((tokenId, nftIndex) => <NFTDisplay
									tokenId={tokenId} key={tokenId}
									minerInfo={minerInfos[nftIndex]}
									refresh={refresh}
									tokenContractAddress={tokenContractAddress}
									modalMinerShow={modalMinerShow}
								/>)}
							</div>
							:
							<div className="text-center">No NFTs founds.</div>
						)
					}
				</div>
			</>
			:
			<div className="bg-yellow-100 border border-yellow-400 text-gray-700 px-4 py-3 rounded sm:w-full">Please connect wallet.</div>
		}

		<ModalMiner
			userBalance={userBalance}
			tokenContractAddress={tokenContractAddress}
			modalMinerTokenId={modalMinerTokenId}
			onClose={modalMinerClose}
			refresh={refresh}
		/>

		<ModalCalculator
			tokenContractAddress={tokenContractAddress}
			onClose={modalCalculatorClose}
		/>
	</div>;
}

function ModalMiner({ userBalance, tokenContractAddress, modalMinerTokenId, onClose, refresh }) {
	const [minerBalance, setMinerBalance] = useState(0);
	const [durationDays, setDurationDays] = useState(7);
	const [apr, setApr] = useState(0);
	const publicClient = usePublicClient();

	useEffect(() => {
		async function fetch() {
			if (!modalMinerTokenId || !publicClient)
				return null;
			const apr = await publicClient.readContract({
				address: tokenContractAddress,
				abi: PulseSpaceApesTokenAbi.abi,
				functionName: "calculateAprForToken",
				args: [modalMinerTokenId, durationDays],
			});
			setApr(Number(apr));
		}
		fetch();
	}, [publicClient, tokenContractAddress, modalMinerTokenId, durationDays]);

	return <dialog id="minerModal" className="rounded-xl p-4 border-4 border-[#a061ae] bg-slate-900 text-white">
		<div className="modal-box">
			<h3 className="font-bold text-lg">Mine APIUM</h3>
			<div className="modal-action">
				<form method="dialog">
					<div className="mt-4 flex flex-col">
						<label>Your available balance:</label>
						<NumericFormat
							readOnly
							thousandSeparator=","
							decimalSeparator="."
							decimalScale={18}
							suffix=" APIUM"
							className="bg-slate-700 text-white border rounded-xl p-2 text-right"
							value={userBalance?.value ? formatEther(userBalance?.value) : '-'} />
					</div>
					<div className="mt-4 flex flex-col">
						<label>Amount to add to miner:</label>
						<NumericFormat
							id="minerBalance"
							thousandSeparator=","
							decimalSeparator="."
							decimalScale={18}
							min={0}
							suffix=" APIUM"
							className="bg-transparent text-white border rounded-xl p-2 text-right"
							value={formatEther(minerBalance)}
							onValueChange={values => {
								setMinerBalance(parseEther(values.value));
							}} />
					</div>
					<div className="mt-4 flex flex-col">
						<div className="flex flex-row">
							<label>Mining duration:</label>
							<label className="grow text-right">APR: {apr / 100}%</label>
						</div>
						<NumericFormat
							id="minerDurationDays"
							thousandSeparator=","
							decimalScale={0}
							min={7}
							suffix=" days"
							className="bg-transparent text-white border rounded-xl p-2 text-right"
							value={durationDays}
							onValueChange={values => {
								setDurationDays(values.value);
							}} />
					</div>
					{/* if there is a button in form, it will close the modal */}
					<div className="mt-4 flex flex-row gap-4">
						<Web3Button
							disabled={false}
							className="btn h-11 w-32"
							contractAddress={tokenContractAddress}
							contractAbi={PulseSpaceApesTokenAbi.abi}
							action={async (ctx) => {
								console.log("startMining", modalMinerTokenId, minerBalance, durationDays);
								return ctx.estimateAndSend("startMining", [modalMinerTokenId, minerBalance, durationDays]);
							}}
							onError={(error) => { alert(error); }}
							onSuccess={() => {
								onClose();
								refresh();
							}}
						>
							Start
						</Web3Button>
						<button className="btn h-11 w-32">
							Close
						</button>
					</div>
				</form>
			</div>
		</div>
	</dialog>;
}

function ModalCalculator({ tokenContractAddress, onClose }) {
	const [minerBalance, setMinerBalance] = useState(1000000000000000000000000n);
	const [rank, setRank] = useState(1816);
	const [durationDays, setDurationDays] = useState(365);
	const [apr, setApr] = useState(0);
	const publicClient = usePublicClient();

	useEffect(() => {
		async function fetch() {
			if (!rank || !durationDays || !publicClient)
				return 0;
			try {
				const apr = await publicClient.readContract({
					address: tokenContractAddress,
					abi: PulseSpaceApesTokenAbi.abi,
					functionName: "calculateAprForRank",
					args: [rank, durationDays],
				});
				setApr(Number(apr));
			}
			catch (err) {
				setApr('-');
			}
		}
		fetch();
	}, [publicClient, tokenContractAddress, rank, durationDays]);

	const minerYield = useMemo(() => {
		const b = minerBalance;
		const a = (apr && apr !== '-') ? parseUnits(`${apr}`, 0) : 0n;
		const d = durationDays ? parseUnits(`${durationDays}`, 0) : 0n;
		return b * a * d / (365n * 100n * 100n);
	}, [minerBalance, durationDays, apr]);

	return <dialog id="calculatorModal" className="rounded-xl p-4 border-4 border-[#a061ae] bg-slate-900 text-white">
		<div className="modal-box">
			<h3 className="font-bold text-lg">Calculate APIUM Yield</h3>
			<div className="modal-action">
				<form method="dialog">
					<div className="mt-4 flex flex-col">
						<label>Amount to add to miner:</label>
						<NumericFormat
							id="calculatorBalance"
							thousandSeparator=","
							decimalSeparator="."
							decimalScale={18}
							min={0}
							suffix=" APIUM"
							className="bg-transparent text-white border rounded-xl p-2 text-right"
							value={formatEther(minerBalance)}
							onValueChange={values => {
								setMinerBalance(parseEther(values.value));
							}} />
					</div>
					<div className="mt-4 flex flex-col">
						<label>Space Ape rank:</label>
						<NumericFormat
							id="calculatorRank"
							thousandSeparator=","
							decimalScale={0}
							min={0}
							prefix="#"
							className="bg-transparent text-white border rounded-xl p-2 text-right"
							value={rank}
							onValueChange={values => {
								setRank(values.value);
							}} />
					</div>
					<div className="mt-4 flex flex-col">
						<div className="flex flex-row">
							<label>Mining duration:</label>
						</div>
						<NumericFormat
							id="calculatorDurationDays"
							thousandSeparator=","
							decimalScale={0}
							min={7}
							suffix=" days"
							className="bg-transparent text-white border rounded-xl p-2 text-right"
							value={durationDays}
							onValueChange={values => {
								setDurationDays(values.value);
							}} />
					</div>
					<div className="mt-4 flex flex-col">
						<label className="grow text-center text-xl">APR: {apr / 100}%</label>
						<label className="mt-4 grow text-center text-xl">Yield: {formatAmount(minerYield)} APIUM</label>
					</div>
					<div className="mt-4 flex flex-row gap-4 justify-center">
						<button className="btn h-11 w-32">
							Close
						</button>
					</div>
				</form>
			</div>
		</div>
	</dialog>;
}
