import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WagmiConfig, createConfig } from 'wagmi';
import { pulsechain, pulsechainV4 } from 'wagmi/chains';

import './App.css';

import About from './components/About';
import CallToAction from './components/CallToAction';
import Claim from './components/Claim';
import HeroSection from './components/HeroSection';
import Layout from './components/Layout';
import Quest from './components/Quest';
import { Mining } from './components/Mining';
import { Swap } from './components/Swap';


// const metadata = {
//   name: 'Pulse Space Apes',
//   description: 'Pulse Space Apes DApp',
//   url: 'https://pulseapes.space',
//   // icons: ['https://avatars.githubusercontent.com/u/37784886']
// }

const wagmiConfig = createConfig(
  getDefaultConfig({
    // Required API Keys
    // alchemyId: process.env.ALCHEMY_ID, // or infuraId
    walletConnectProjectId: '8c594cc42ab8432db3262ca48f4e815b',

    // Required
    appName: "Pulse Space Apes",
    // Optional
    appDescription: "Pulse Space Apes DApp",
    appUrl: "https://pulseapes.space",
    appIcon: "https://pulseapes.space/apes_logo.png",
    chains: [pulsechain, pulsechainV4],
  }),
);

function Home() {
  return <>
    <HeroSection />
    <About />
    <CallToAction />
  </>;
}

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <ConnectKitProvider
        theme="midnight"
        mode="dark"
        customTheme={{
          "--ck-font-family": "PixelCode, sans-serif",
          "--ck-connectbutton-border-radius": "30px",
          "--ck-connectbutton-border": "#a061ae",
          "--ck-connectbutton-color": "#ffffff",
          "--ck-connectbutton-background": "transparent",
          "--ck-connectbutton-hover-background": "#a061ae"
        }}
      >
        <Layout title="Welcome to Pulse Space Apes.">
          <main>
            <BrowserRouter>
              <Routes>
                <Route path="/">
                  <Route index element={<Home />} />
                  <Route path="swap" element={<Swap />} />
                  <Route path="mining" element={<Mining />} />
                  <Route path="quest" element={<Quest />} />
                  <Route path="claim" element={<Claim />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </main>
        </Layout>
      </ConnectKitProvider>
    </WagmiConfig>
  );
}

export default App;
