import Container from "./Container.jsx";

export default function Quest({ children }) {
  return (
    <div id="about">
      <Container>
          {/* Add video element here */}
          <div className="mt-12 group p-6 sm:p-0.5 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
          <video src="piechart.mp4" autoPlay loop muted playsInline className="w-full h-auto" />
          </div>
          </div>
        <div className="mt-24 mb-24 space-y-2 text-center">
          <h2 className="mb-8 text-center text-4xl font-bold text-white dark:text-white md:text-5xl">APIUM Quest</h2>
          <p className="text-center text-xl text-white dark:text-gray-300">
          Each on-chain quest is divided into a separate season, covering a total of 65 percent of the APIUM token allocation, currently with 4 seasons. The remaining 35 percent is paired in a pool with the Hocus Pocus token.<br></br><br></br>
          The on-chain quest will be divided into 6 snapshots over several months with 6 moments of airdrops.
  </p>
        </div> 
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          <div className="group p-6 sm:p-8 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
              <img src="./images/quest1.png"
                alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" />
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white">
              Quest 1: Jungle Beginnings (Space Apes NFT)
              </h3>
              <a className="inline-block" href="/">
                <span className="text-white dark:text-blue-300">
                  <div className="mt-6 mb-8 text-white dark:text-gray-300">
                    <ul>
                    <li>Status: Open</li>
                      <br></br>
                      {/* <li>Objective: Explore the dense jungle and gather essential resources.</li>
                      <br></br>
                      <li>Description: The Pulse Space Apes start their journey in the heart of the jungle. They must navigate the lush, unknown terrain, gather food, and learn to survive in this wild environment.</li>
                      <br></br> */}
                      <li>Task: Hold the Pulse Space Ape NFT. The more NFTs you hold, the larger your APIUM allocation.</li>
                      <br></br>
                      {/* <li>Snapshots will be announced in the Pulse Space Apes Telegram channel.</li> */}
                    </ul>
                  </div>
                </span>
              </a>
            </div>
          </div>
          <div className="group p-6 sm:p-8 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
              <img src="./images/quest3.png"
                alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" />
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white">
              Quest 2: River of Wisdom (Hold Hocus Pocus)
              </h3>
              <a className="inline-block" href="/">
                <span className="text-white dark:text-blue-300">
                  <div className="mt-6 mb-8 text-white dark:text-gray-300">
                    <ul>
                    <ul>
                    <li>Status: Open</li>
                      <br></br>
                      {/* <li>Objective: Follow the mystical river to uncover ancient knowledge.</li>
                      <br></br>
                      <li>Description: The apes discover a mysterious river that is said to hold ancient knowledge about their founder's whereabouts. They embark on a journey downstream, facing trials and tribulations while seeking enlightenment.</li>
                      <br></br> */}
                      <li>Task: Hold 10,000,000 Hocus Pocus HOC tokens in your wallet. The more you hold, the biger the allocation.</li>
                      <br></br>
                      {/* <li>Snapshots will be announced in the Pulse Space Apes Telegram channel.</li> */}
                    </ul>
                    </ul>
                  </div>
                </span>
              </a>
            </div>
          </div>
          <div className="group p-6 sm:p-8 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
              <img src="./images/quest4.png"
                alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" />
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white">
              Quest 3: The Space Beacon (Cauldron)
              </h3>
              <a className="inline-block" href="/">
                <span className="text-white dark:text-blue-300">
                  <div className="mt-6 mb-8 text-white dark:text-gray-300">
                  <ul>
                    <li>Status: Open</li>
                      <br></br>
                      {/* <li>Objective: Construct a beacon to signal their presence to the stars.</li>
                      <br></br>
                      <li>Description: In the depths of the jungle, the apes find remnants of an old space beacon. They must gather materials and rebuild it to send a signal into space, hoping to attract the attention of their founder.</li>
                      <br></br> */}
                      <li>Task: Start a Hocus Pocus Cauldron (stake HOC) with at least 10,000,000 HOC tokens. The larger the cauldron, the bigger the allocation.</li>
                      <br></br>
                      {/* <li>Snapshots will be announced in the Pulse Space Apes Telegram channel.</li> */}
                    </ul>
                  </div>
                </span>
              </a>
            </div>
          </div>
          <div className="group p-6 sm:p-8 rounded-2xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/10">
            <div className="relative overflow-hidden rounded-xl">
              <img src="./images/quest5.png"
                alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" />
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white">
                {/* The first Apes to arrive on PulseChain. Join us in our battle. */}
                Quest 4: Cosmic Odyssey (Provide Liquidity)
              </h3>
              <a className="inline-block" href="/">
                <span className="text-white dark:text-blue-300">
                  <div className="mt-6 mb-8 text-white dark:text-gray-300">
                  <ul>
                  <li>Status: Open</li>
                      <br></br>
                      {/* <li>Objective: Launch into space and embark on a cosmic adventure.</li>
                      <br></br>
                      <li>Description: With the beacon operational, the apes are ready to leave the jungle behind. They launch into space and begin their cosmic odyssey, encountering celestial wonders, cosmic challenges, and strange new worlds.</li>
                      <br></br> */}
                      <div>
                  <p>
                    Task: Provide liquidity in one of our official pools for Pulse Space Apes and HOC. Snapshot 2 will use the following pools 
                    <br></br><span style={{margin: '0 5px'}}>
                      <a href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/add/V2/0xd22e78c22d7e77229d60cc9fc57b0e294f54488e/0x970f2e60b1d66381f5f9d279d31f0d5f09ceebe6" style={{color: 'blue'}}>- HOC/APIUM PulseX</a> 
                    </span>
                   <br></br> 
                    <span style={{margin: '0 5px'}}>
                      <a href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/add/V2/PLS/0xd22E78C22D7E77229d60cc9fC57b0E294F54488E" style={{color: 'blue'}}>- HOC/WPLS PulseX</a> 
                    </span>
                    <br></br>
                    <span style={{margin: '0 5px'}}>
                      <a href="https://phux.io/#/pulse/pool/0xc26e38fc7a803a11eadd2468e6884be41952746d0001000000000000000000f5" style={{color: 'blue'}}>- HOC pool on Phux</a> 
                    </span>
                  </p>
                    </div>
                      </ul>
                  </div>
                </span>
              </a>
            </div>
            </div>
        </div>
      </Container>
    </div>
  );
}